<template>
  <section class="section section-shaped section-lg my-0">
    <div class="shape shape-mainbg"></div>
    <div class="container p-lg-md">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <card
            type="secondary"
            shadow
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0"
          >
            <template>
              <div class="text-center text-muted mb-4">
                <h5><strong>Sign in</strong></h5>
              </div>
              <form role="form" @submit.prevent="login">
                <base-input
                  alternative
                  v-model="email"
                  type="email"
                  class="mb-3"
                  placeholder="Email"
                  prepend-icon="ni ni-email-83"
                  :error="errorFor('email')"
                >
                </base-input>
                <base-input
                  alternative
                  v-model="password"
                  type="password"
                  placeholder="password"
                  prepend-icon="ni ni-lock-circle-open"
                  :error="errorFor('password')"
                >
                </base-input>
                <base-checkbox v-model="remember">
                  Remember me
                </base-checkbox>
                <div class="text-center">
                  <base-button
                    type="primary"
                    native-type="submit"
                    class="my-4"
                    :disabled="sendingRequest"
                    >Sign In</base-button
                  >
                </div>
              </form>
            </template>

            <template>
              <div class="btn-wrapper btn-group-sm text-center mt-3">
                <router-link
                  :to="{ name: 'subscribe', query: { plan: 1 } }"
                  class="nav-item btn btn-link text-default"
                  >Create new account</router-link
                >
                <router-link
                  :to="{ name: 'forgot-password' }"
                  id="forgot_password"
                  class="nav-item btn btn-link text-default"
                  >Forgot Password?</router-link
                >
              </div>
            </template>
          </card>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import handlesApiErrors from "@/mixins/handlesApiErrors";

export default {
  name: "login",

  mixins: [handlesApiErrors],

  data: function() {
    return {
      email: "",
      password: "",
      remember: false,

      sendingRequest: false,
    };
  },

  methods: {
    async login() {
      this.clearApiErrors();
      this.sendingRequest = true;

      try {
        await this.$store.dispatch("login", {
          email: this.email,
          password: this.password,
        });
        this.klaviyoIdentify(this.email, "");
        this.klaviyoLogin();
        this.$router.push({ name: "main" });
      } catch (e) {
        this.handleApiErrors(e);
      }

      this.sendingRequest = false;
    },
  },
};
</script>
